<template>

  <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
    <defs>
      <filter id="gooey">
        <feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur" />
        <feColorMatrix in="blur" type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="highContrastGraphic" />
        <feComposite in="SourceGraphic" in2="highContrastGraphic" operator="atop" />
      </filter>
    </defs>
  </svg>

  <button id="gooey-button" >
    Mega Awesome
    <span class="bubbles">
            <span class="bubble"></span>
            <span class="bubble"></span>
            <span class="bubble"></span>
            <span class="bubble"></span>
            <span class="bubble"></span>
            <span class="bubble"></span>
            <span class="bubble"></span>
            <span class="bubble"></span>
            <span class="bubble"></span>
            <span class="bubble"></span>
        </span>
  </button>

</template>

<script>
export default {
  name: "Button4",


  data() {
    return    {


    }
  },

  methods : {

  }
}
</script>

<style scoped>
body,
html {
  align-items: center;
  background-color: #0c1016;
  display: flex;
  font-family: "Roboto";
  font-size: 10px;
  height: 100%;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 100%;
}

* {
  box-sizing: border-box;
}

svg {
  position: absolute;
  top: -4000px;
  left: -4000px;
}

#gooey-button {
  padding: 1rem 3rem;
  font-size: 2rem;
  border: none;
  color: #0c1016;
  filter: url("#gooey");
  position: relative;
  background-color: #00FF80;
}
#gooey-button:focus {
  outline: none;
}
#gooey-button .bubbles {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;


}
#gooey-button .bubbles .bubble {
  background-color: #00FF80;
  border-radius: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  z-index: -1;
}
#gooey-button .bubbles .bubble:nth-child(1) {
  left: 39px;
  width: 25px;
  height: 25px;
  -webkit-animation: move-1 3.02s infinite;
  animation: move-1 3.02s infinite;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
#gooey-button .bubbles .bubble:nth-child(2) {
  left: 46px;
  width: 25px;
  height: 25px;
  -webkit-animation: move-2 3.04s infinite;
  animation: move-2 3.04s infinite;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
#gooey-button .bubbles .bubble:nth-child(3) {
  left: 67px;
  width: 25px;
  height: 25px;
  -webkit-animation: move-3 3.06s infinite;
  animation: move-3 3.06s infinite;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
#gooey-button .bubbles .bubble:nth-child(4) {
  left: 95px;
  width: 25px;
  height: 25px;
  -webkit-animation: move-4 3.08s infinite;
  animation: move-4 3.08s infinite;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}
#gooey-button .bubbles .bubble:nth-child(5) {
  left: 15px;
  width: 25px;
  height: 25px;
  -webkit-animation: move-5 3.1s infinite;
  animation: move-5 3.1s infinite;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
#gooey-button .bubbles .bubble:nth-child(6) {
  left: 40px;
  width: 25px;
  height: 25px;
  -webkit-animation: move-6 3.12s infinite;
  animation: move-6 3.12s infinite;
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}
#gooey-button .bubbles .bubble:nth-child(7) {
  left: 33px;
  width: 25px;
  height: 25px;
  -webkit-animation: move-7 3.14s infinite;
  animation: move-7 3.14s infinite;
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}
#gooey-button .bubbles .bubble:nth-child(8) {
  left: 63px;
  width: 25px;
  height: 25px;
  -webkit-animation: move-8 3.16s infinite;
  animation: move-8 3.16s infinite;
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
}
#gooey-button .bubbles .bubble:nth-child(9) {
  left: 89px;
  width: 25px;
  height: 25px;
  -webkit-animation: move-9 3.18s infinite;
  animation: move-9 3.18s infinite;
  -webkit-animation-delay: 1.8s;
  animation-delay: 1.8s;
}
#gooey-button .bubbles .bubble:nth-child(10) {
  left: 48px;
  width: 25px;
  height: 25px;
  -webkit-animation: move-10 3.2s infinite;
  animation: move-10 3.2s infinite;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

@-webkit-keyframes move-1 {
  0% {
    transform: translate(0, 0);
  }
  99% {
    transform: translate(0, -88px);
  }
  100% {
    transform: translate(0, 0);
    opacity: 0;
  }
}

@keyframes move-1 {
  0% {
    transform: translate(0, 0);
  }
  99% {
    transform: translate(0, -88px);
  }
  100% {
    transform: translate(0, 0);
    opacity: 0;
  }
}
@-webkit-keyframes move-2 {
  0% {
    transform: translate(0, 0);
  }
  99% {
    transform: translate(0, -52px);
  }
  100% {
    transform: translate(0, 0);
    opacity: 0;
  }
}
@keyframes move-2 {
  0% {
    transform: translate(0, 0);
  }
  99% {
    transform: translate(0, -52px);
  }
  100% {
    transform: translate(0, 0);
    opacity: 0;
  }
}
@-webkit-keyframes move-3 {
  0% {
    transform: translate(0, 0);
  }
  99% {
    transform: translate(0, -107px);
  }
  100% {
    transform: translate(0, 0);
    opacity: 0;
  }
}
@keyframes move-3 {
  0% {
    transform: translate(0, 0);
  }
  99% {
    transform: translate(0, -107px);
  }
  100% {
    transform: translate(0, 0);
    opacity: 0;
  }
}
@-webkit-keyframes move-4 {
  0% {
    transform: translate(0, 0);
  }
  99% {
    transform: translate(0, -123px);
  }
  100% {
    transform: translate(0, 0);
    opacity: 0;
  }
}
@keyframes move-4 {
  0% {
    transform: translate(0, 0);
  }
  99% {
    transform: translate(0, -123px);
  }
  100% {
    transform: translate(0, 0);
    opacity: 0;
  }
}
@-webkit-keyframes move-5 {
  0% {
    transform: translate(0, 0);
  }
  99% {
    transform: translate(0, -103px);
  }
  100% {
    transform: translate(0, 0);
    opacity: 0;
  }
}
@keyframes move-5 {
  0% {
    transform: translate(0, 0);
  }
  99% {
    transform: translate(0, -103px);
  }
  100% {
    transform: translate(0, 0);
    opacity: 0;
  }
}
@-webkit-keyframes move-6 {
  0% {
    transform: translate(0, 0);
  }
  99% {
    transform: translate(0, -61px);
  }
  100% {
    transform: translate(0, 0);
    opacity: 0;
  }
}
@keyframes move-6 {
  0% {
    transform: translate(0, 0);
  }
  99% {
    transform: translate(0, -61px);
  }
  100% {
    transform: translate(0, 0);
    opacity: 0;
  }
}
@-webkit-keyframes move-7 {
  0% {
    transform: translate(0, 0);
  }
  99% {
    transform: translate(0, -129px);
  }
  100% {
    transform: translate(0, 0);
    opacity: 0;
  }
}
@keyframes move-7 {
  0% {
    transform: translate(0, 0);
  }
  99% {
    transform: translate(0, -129px);
  }
  100% {
    transform: translate(0, 0);
    opacity: 0;
  }
}
@-webkit-keyframes move-8 {
  0% {
    transform: translate(0, 0);
  }
  99% {
    transform: translate(0, -57px);
  }
  100% {
    transform: translate(0, 0);
    opacity: 0;
  }
}
@keyframes move-8 {
  0% {
    transform: translate(0, 0);
  }
  99% {
    transform: translate(0, -57px);
  }
  100% {
    transform: translate(0, 0);
    opacity: 0;
  }
}
@-webkit-keyframes move-9 {
  0% {
    transform: translate(0, 0);
  }
  99% {
    transform: translate(0, -51px);
  }
  100% {
    transform: translate(0, 0);
    opacity: 0;
  }
}
@keyframes move-9 {
  0% {
    transform: translate(0, 0);
  }
  99% {
    transform: translate(0, -51px);
  }
  100% {
    transform: translate(0, 0);
    opacity: 0;
  }
}
@-webkit-keyframes move-10 {
  0% {
    transform: translate(0, 0);
  }
  99% {
    transform: translate(0, -115px);
  }
  100% {
    transform: translate(0, 0);
    opacity: 0;
  }
}
@keyframes move-10 {
  0% {
    transform: translate(0, 0);
  }
  99% {
    transform: translate(0, -115px);
  }
  100% {
    transform: translate(0, 0);
    opacity: 0;
  }
}

</style>
