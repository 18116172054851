<template>

<h1>8</h1>

</template>

<script>
export default {
  name: "Button8",


  data() {
    return    {


    }
  },

  methods : {

  }
}
</script>

<style scoped>



</style>
