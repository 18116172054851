<template>

  <ul class="text-center">

    <li class="text-3xl">NR. 1
      <button1></button1>
    </li>
    <li class="mt-40 text-3xl">
      NR. 2
      <button2></button2>

       </li>
    <li class="mt-40 text-3xl flex flex-col">
      NR. 3
      <button3></button3>
    </li>
    <li class="mt-40 text-3xl inline-grid">
      NR. 4
      <button4></button4>
    </li>

    <li class="mt-40 text-3xl">
      NR. 5
      <button5></button5>
    </li>


    <li class="mt-40 text-3xl">
      NR. 6
      <button6></button6>
    </li>


    <li class="mt-40 text-3xl">
      NR. 7
      <button7></button7>
    </li>

    <li class="mt-40 text-3xl">
      NR. 8
      <button8></button8>
    </li>

    <li class="mt-40 text-3xl">
      NR. 9
      <button9></button9>
    </li>


  </ul>


</template>

<script>

import Button1 from "@/components/partials/Buttons/ButtonNr1";
import Button2 from "@/components/partials/Buttons/ButtonNr2";
import Button3 from "@/components/partials/Buttons/ButtonNr3";
import Button4 from "@/components/partials/Buttons/ButtonNr4";
import Button5 from "@/components/partials/Buttons/ButtonNr5";
import Button6 from "@/components/partials/Buttons/ButtonNr6";
import Button7 from "@/components/partials/Buttons/ButtonNr7";
import Button8 from "@/components/partials/Buttons/ButtonNr8";
import Button9 from "@/components/partials/Buttons/ButtonNr9";

export default {
  name: "Buttons",

  components: {Button1,Button2,Button3,Button4,Button5,Button6,Button7,Button8,Button9}
}
</script>

<style scoped>

</style>
